import React from "react";
import { useEffect } from "react";
import { GoogleLogout } from "react-google-login";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, resetAuth } from "../features/auth/authSlice";
import { resetData } from "../features/tabin/tabinSlice";
import { Mixpanel } from "../app/mixpanel-instance";

import ReactGA from "react-ga4";

const clientId = process.env.REACT_APP_GOOGLE_CLIENTID;

function Logout() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user, isLoading, isError, isSuccess, isSignedIn, message } =
		useSelector((state) => state.auth);

	const onSuccess = (res) => {
		console.log("Log out successfully", res);
		dispatch(logout());
		dispatch(resetAuth());
		dispatch(resetData());

		Mixpanel.track("user logout", {
			user: user._id,
		});
		ReactGA.event({
			category: "User Logout",
			action: "User Logged out",
			label: "Google Account logout",
			value: user._id,
		});
		navigate("/login");
	};

	useEffect(() => {
		if (isError) {
			console.log(message);
		}
		if (!user) {
			navigate("/");
			console.log("no user found");
		}

		Mixpanel.track_pageview();

		ReactGA.send({
			hitType: "pageview",
			page: "/logout",
			title: "logout",
		});
	}, [
		user,
		isLoading,
		isError,
		isSuccess,
		isSignedIn,
		message,
		navigate,
		dispatch,
	]);

	return (
		<div>
			<div className={`flex sm:mb-8 mb-6 xs:p-1 lg:pl-[200px]`}>
				<div className='justify-center items-center pt-[20px]  xs:grid  xs:pl-[50px] sm:pt-[150px] sm:inline-flex xs:w-[100%] lg:w-[90%] '>
					<div className='flex justify-center items-center bg-white w-[100%] xs:order-last sm:-order-1 sm:border-none'>
						<span className='flex'>
							<img
								src='tabin_people_laptop2.png'
								alt=''
								className='h-[auto] w-[450px] sm:pr-10'
							/>
						</span>
					</div>
					<div className='flex h-[100%] w-[100%] justify-center'>
						<div className='rs:hidden sm:inline-block h-[75%] w-[5%]'>
							<div className='block h-[50%] w-[100%] bg-[#FF6E8B]'></div>
							<div className='block h-[50%] w-[100%] bg-[#AAF0DA]'></div>
						</div>
						<div className='inline-block w-[100%] justify-center items-center bg-white sm:mt-[120px]'>
							<span className='flex justify-center w-[100%] text-[40px] font-semibold flexcenter pt-[30px] pb-[15px]'>
								Sign Out
							</span>
							<div className='googlebtncontainer'>
								<GoogleLogout
									clientId={clientId}
									render={(renderProps) => (
										<button
											className='googlebtn'
											onClick={renderProps.onClick}
											disabled={renderProps.disabled}
										>
											<span className='googlelogowhitebox'>
												<img
													className='googlelogo'
													src='google_logo.png'
													alt=''
												/>
											</span>
											LOGOUT WITH GOOGLE
										</button>
									)}
									icon={true}
									buttonText='Logout with Google'
									onLogoutSuccess={onSuccess}
									isSignedIn={false}
								></GoogleLogout>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Logout;
