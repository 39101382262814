import axios from "axios";

const TAG = "userService.";
const GET_ALL_USER_API = process.env.REACT_APP_USER_API;

// Register user or get user

const getAllUsers = async (token) => {
	console.log(TAG + "getAllUsers");
	console.log("ENVIRONMENT", process.env.REACT_APP_ENV);
	console.log("GET_ALL_USER_API", GET_ALL_USER_API);
	// get auth
	const authHeaders = getAuthHeaders(token);
	const response = await axios.get(GET_ALL_USER_API, authHeaders);

	if (response.data) {
		console.log("getAllUsers response ", response);
		localStorage.setItem("all_users", JSON.stringify(response.data.users));
	}

	return response.data;
};

const updateSingleUser = async (user, token) => {
	console.log(TAG + "updateSingleUser");

	const API = GET_ALL_USER_API + user._id;
	console.log("API", API);

	console.log("user", user);

	//  get auth
	const authHeaders = getAuthHeaders(token);
	const response = await axios.put(API, user, authHeaders);

	if (response.data) {
		console.log("getAllUsers response ", response);
		// localStorage.setItem("all_users", JSON.stringify(response.data.users));
	}

	return response.data;
};

const getAuthHeaders = (token) => {
	const authHeaders = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	return authHeaders;
};

const userService = {
	getAllUsers,
	updateSingleUser,
};

export default userService;
