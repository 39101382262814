import React from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";

import Features from "../components/Features";
import Hero from "../components/Hero";
import HowItWorks from "../components/HowItWorks";
import Stats from "../components/Stats";
import CookieConsentBox from "../components/CookieConsentBox";
import Footer from "../components/Footer";
import { Mixpanel } from "../app/mixpanel-instance";

function Home() {
	useEffect(() => {
		Mixpanel.track_pageview();
		ReactGA.send({
			hitType: "pageview",
			page: "/",
			title: "home",
		});
	});
	return (
		<>
			<Hero />
			<Features />
			<Stats />
			<HowItWorks />
			<Footer />
			<CookieConsentBox />
		</>
	);
}
export default Home;
