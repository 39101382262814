import React from "react";
import { useDispatch } from "react-redux";
import { deleteTabFromBin } from "../features/tabin/tabinSlice";
import { useEffect, useState } from "react";
// import { getUserTabins } from "../features/tabin/tabinSlice";
import { Mixpanel } from "../app/mixpanel-instance";
const TAG = "components.Tabview";

function Tabview({ binId, binData, setBinData, setTabins, deleteBin }) {
	console.log(TAG + ".Tabview");
	// list of tabs for a bin
	const [tabs, setTabs] = useState([]);

	const dispatch = useDispatch();
	// const { user } = useSelector((state) => state.auth);

	const tabClickHandler = (e) => {
		const tab_url = e.target.getAttribute("data-url");
		Mixpanel.track("tab clicked in tabcase", {
			taburl: tab_url,
		});
		window.open(tab_url, "_blank");
	};

	const delTabBtnClickHandler = async (e) => {
		console.log("tabs.length before ", tabs.length);
		console.log(TAG + ".delTabBtnClickHandler");
		let tabId = e.target.id;

		// console.log("btnClickHandler id", tabId);
		// console.log("bin id", binId);
		await dispatch(deleteTabFromBin({ binId, tabId }));
		setTabs((oldTabs) => {
			return oldTabs.filter((tab) => tab.id !== tabId);
		});

		setBinData((oldbin) => {
			const updatedTabs = oldbin.tabs.filter((tab) => tab.id !== tabId);
			return {
				...oldbin,
				tabs: updatedTabs,
			};
		});

		Mixpanel.track("tab deleted frontend", {
			tabin_id: binId,
			tab_id: tabId,
		});

		if (tabs.length === 1) {
			await deleteBin(binId);
			console.log("all tabs are deleted ");
			//window.location.reload();
		} else {
			const tabin = JSON.parse(localStorage.getItem("bins"));
			setTabins(tabin);
		}
		console.log("tabs.length after", tabs.length);
	};
	useEffect(() => {
		setTabs(binData.tabs);
	}, [setTabs, binData]);
	return tabs.map((tab) => {
		return (
			<>
				<div className='flex w-[100%] justify-center items-center'>
					<div className='inline-flex w-[100%] justify-center items-center h-[auto] p-2 border-2 cursor-pointer xs:w-[100%] md:w-[90%] xl:w-[80%]'>
						<li
							className='inline-flex justify-between w-[100%]'
							id={tab.id}
							onClick={tabClickHandler}
						>
							<div
								id={tab.id}
								className='flex w-[10%] justify-center items-center'
							>
								<img
									src={tab.fav_icon_url ? tab.fav_icon_url : " "}
									alt=''
									className='flex h-[40px] w-[40px]'
								></img>
							</div>
							<div
								data-url={tab.url}
								id={tab.id}
								className='flex justify-center items-center w-[86%] xs:text-[15px] md:text-lg hover:bg-gray-200'
							>
								{tab.title
									? tab.title.length > 100
										? tab.title.slice(0, 99) + "...."
										: tab.title
									: tab.url.slice(0, 23)}
							</div>
						</li>
						<div className='flex justify-end items-center p-1'>
							<button
								className='flex btn bg-[#f66f6f] p-2 hover:bg-red-600'
								id={tab.id}
								onClick={delTabBtnClickHandler}
							>
								X
							</button>
						</div>
					</div>
				</div>
			</>
		);
	});
}

export default Tabview;
