import React from "react";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { FaYoutube } from "react-icons/fa";
import YoutubeEmbed from "../components/YoutubeEmbed";
import { Mixpanel } from "../app/mixpanel-instance";

const Howto = () => {
	useEffect(() => {
		Mixpanel.track_pageview();
		ReactGA.send({
			hitType: "pageview",
			page: "/pricing",
			title: "pricing",
		});
	}, []);
	return (
		<div>
			<div className='columns-lg max-w-5xl mx-auto mt-10 mb-20'>
				<div className='flex justify-center'>
					<h1 className='text-4xl'>How to Create Tabin</h1>
				</div>
				<div className='flex justify-center mt-4'>
					<YoutubeEmbed embedId='hWCj-N2OCBY' />
				</div>
				<div className='flex justify-center pt-4 mt-10'>
					<h1 className='text-4xl'>How to Schedule Tabin</h1>
				</div>
				<div className='flex justify-center mt-4'>
					<YoutubeEmbed embedId='3WXjKCyx0lE' />
				</div>
				<div className='flex justify-center mt-10'>
					<h1 className='text-4xl'>How to Share Tabin</h1>
				</div>
				<div className='flex justify-center mt-4'>
					<YoutubeEmbed embedId='1o7r0f1mYFM' />
				</div>
				<div className='flex justify-center pt-4 mt-10'>
					<h1 className='text-4xl'>How to Delete Tabin</h1>
				</div>
				<div className='flex justify-center mt-4'>
					<YoutubeEmbed embedId='Ln1ei55l92I' />
				</div>
			</div>
		</div>
	);
};

export default Howto;
