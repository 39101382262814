import axios from "axios";
import Cookie from "js-cookie";
import { reset } from "../tabin/tabinSlice";

const REGISTER_USER_API = process.env.REACT_APP_USER_API;

// Register user or get user

const register = async (userData) => {
	console.log("ENVIRONMENT", process.env.REACT_APP_ENV);
	console.log("REGISTER_USER_API", REGISTER_USER_API);
	const response = await axios.post(REGISTER_USER_API, userData);

	if (response.data) {
		console.log("register data: ", response.data);
		Cookie.set("user", JSON.stringify(response.data.user));
		Cookie.set("token", JSON.stringify(response.data.token));
		localStorage.setItem("user", JSON.stringify(response.data.user));
		localStorage.setItem("token", JSON.stringify(response.data.token));
		console.log(Cookie.get("user"));
	}

	return response.data;
};

const logout = async () => {
	console.log("logging out.....", sessionStorage.getItem("user"));
	localStorage.removeItem("user");
	localStorage.removeItem("token");
	localStorage.removeItem("bins");
	localStorage.removeItem("all_users");
	localStorage.removeItem("tabin_center_tabins");
	reset();
};

const authService = {
	register,
	logout,
};

export default authService;
