import React from "react";
import { useSelector } from "react-redux";
import SignUpBox from "../components/SignUpBox";

const SignUpFlow = () => {
	const user = useSelector((state) => state.auth.user);

	const signUpDataArray = [];

	return (
		<div>
			<SignUpBox />
		</div>
	);
};

export default SignUpFlow;
