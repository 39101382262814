import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<div className='flex w-full h-[full] justify-center items-start bg-[#FF6E8B] border-t-2'>
			<div className='flex h-[80%] w-[80%] justify-evenly items-start m-6'>
				<div className='grid'>
					<p className='text-xl font-semibold'>Legal</p>
					<Link to='/privacy'>
						<p className='text-sm pt-2 text-gray-700 hover:text-black'>
							Privacy
						</p>
					</Link>
					<Link to='/cookies'>
						<p className='text-sm pt-2 text-gray-700 hover:text-black'>
							Cookies
						</p>
					</Link>
				</div>

				{/* <span></span>
				<span></span> */}
			</div>
		</div>
	);
};

export default Footer;
