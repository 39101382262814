import { React, useEffect } from "react";
import { useSelector } from "react-redux";
import { Mixpanel } from "../app/mixpanel-instance";

const Settings = () => {
	const user = useSelector((state) => state.auth.user);

	useEffect(() => {
		Mixpanel.track_pageview();
	}, []);

	return (
		<div className='p-6'>
			<div className={`flex md:flex-row flex-col justify-center`}>
				<h1 className='text-[40px]'>User Settings</h1>
			</div>
			<div className='grid justify-center pr-[100px] pt-12'>
				<div className=''>
					<h4 className='p-2'>
						Name: {user.first_name + " " + user.last_name}{" "}
					</h4>
					<h4 className='p-2'>Email: {user.email}</h4>
				</div>
			</div>
		</div>
	);
};

export default Settings;
