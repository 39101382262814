import { React, useEffect } from "react";
import { Mixpanel } from "../app/mixpanel-instance";

const Privacy = () => {
	useEffect(() => {
		Mixpanel.track_pageview();
	}, []);
	return (
		<div className='h-[100%]'>
			<iframe
				className='w-full'
				height='1000px'
				src='https://www.iubenda.com/privacy-policy/45640188/legal'
				title='Privacy Policy'
			/>
		</div>
	);
};

export default Privacy;
