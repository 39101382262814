import React from "react";
import { useState } from "react";

import {
	FaCalendarAlt,
	FaSignInAlt,
	FaBriefcase,
	FaSignOutAlt,
	FaHome,
	FaQuestion,
	FaMizuni,
} from "react-icons/fa";
import { SiBuymeacoffee } from "react-icons/si";
import { GrResources } from "react-icons/gr";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Mixpanel } from "../app/mixpanel-instance";

const Navbar = () => {
	const { user } = useSelector((state) => state.auth);
	const [toggle, setToggle] = useState(false);
	return (
		<nav className='flex border-b-4 w-full  py-6 justify-between items-center navbar'>
			<img src='tabin.png' alt='/' className='w-[50px]'></img>
			<ul className='list-none hidden justify-end items-center flex-1 md:flex'>
				{user ? (
					<>
						<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px]'>
							<Link
								className='flex'
								to='/'
								onClick={() => {
									Mixpanel.track("Home link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaHome />
								</i>
								Home
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px]'>
							<Link
								className='flex'
								to='/mytabins'
								onClick={() => {
									Mixpanel.track("My Tabins link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaBriefcase />
								</i>
								My Tabins
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px]'>
							<Link
								className='flex'
								to='/schedule'
								onClick={() => {
									Mixpanel.track("Schedule link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaCalendarAlt />
								</i>
								Schedule Tabins
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px]'>
							<Link
								className='flex'
								to='/howto'
								onClick={() => {
									Mixpanel.track("Howto link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaQuestion />
								</i>
								How To
							</Link>
						</div>
						{user.admin || user.email === "ssingh0188@gmail.com" ? (
							<div className='font-poppins font-normal cursor-pointer text-[16px] mr-10 hover:font-semibold lg:text-[18px]'>
								<Link
									className='flex'
									to='/dashboard'
									onClick={() => {
										Mixpanel.track("Dashboard link NavBar Clicked");
									}}
								>
									<i className='mt-1'>
										<FaBriefcase />
									</i>
									Admin Dashboard
								</Link>
							</div>
						) : (
							<></>
						)}
						<div className='font-poppins font-normal cursor-pointer text-[16px] hover:font-semibold lg:text-[18px]'>
							<Link
								className='flex'
								to='/logout'
								onClick={() => {
									Mixpanel.track("Logout link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaSignOutAlt />
								</i>
								Logout
							</Link>
						</div>
					</>
				) : (
					<>
						<div className='font-poppins font-normal cursor-pointer text-[18px] mr-10 hover:font-semibold'>
							<Link
								className='flex'
								to='/'
								onClick={() => {
									Mixpanel.track("Home link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaHome />
								</i>
								Home
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[18px] mr-10 hover:font-semibold'>
							<Link
								className='flex'
								to='/pricing'
								onClick={() => {
									Mixpanel.track("Pricing link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<SiBuymeacoffee />
								</i>
								Pricing
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[18px] mr-10 hover:font-semibold'>
							<Link
								className='flex'
								to='/howto'
								onClick={() => {
									Mixpanel.track("Howto link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaQuestion />
								</i>
								How To
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[18px] mr-10 hover:font-semibold'>
							<Link
								className='flex'
								to='/extensions'
								onClick={() => {
									Mixpanel.track("Resources link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<GrResources />
								</i>
								Tabin Extension
							</Link>
						</div>
						<div className='font-poppins font-normal cursor-pointer text-[18px] hover:font-semibold'>
							<Link
								className='flex'
								to='/login'
								onClick={() => {
									Mixpanel.track("Login link NavBar Clicked");
								}}
							>
								<i className='mt-1'>
									<FaSignOutAlt />
								</i>
								Login
							</Link>
						</div>
					</>
				)}
			</ul>
			<div className='flex flex-1 justify-end items-center md:hidden'>
				{toggle ? (
					<AiOutlineClose
						className='w-[28px] h-[28px] object-contain'
						onClick={() => setToggle((prev) => !prev)}
					/>
				) : (
					<AiOutlineMenu
						className='w-[28px] h-[28px] object-contain'
						onClick={() => setToggle((prev) => !prev)}
					/>
				)}
				<div
					className={`${
						toggle ? "flex" : "hidden"
					} p-6 bg-[#AAF0DA] absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar border z-40`}
				>
					<ul
						className='list-none flex flex-col justify-end items-center flex-1'
						onClick={() => setToggle((prev) => !prev)}
					>
						{user ? (
							<>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/'
										onClick={() => {
											Mixpanel.track("Home link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaHome />
										</i>
										Home
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/mytabins'
										onClick={() => {
											Mixpanel.track("MyTabins link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaBriefcase />
										</i>
										My Tabins
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/schedule'
										onClick={() => {
											Mixpanel.track("Schedule link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaCalendarAlt />
										</i>
										Schedule Tabins
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/howto'
										onClick={() => {
											Mixpanel.track("Howto link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaQuestion />
										</i>
										How To
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/logout'
										onClick={() => {
											Mixpanel.track("Logout link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaSignInAlt />
										</i>
										Logout
									</Link>
								</div>
							</>
						) : (
							<>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/'
										onClick={() => {
											Mixpanel.track("Home link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaHome />
										</i>
										Home
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/pricing'
										onClick={() => {
											Mixpanel.track("Pricing link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<SiBuymeacoffee />
										</i>
										pricing
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/howto'
										onClick={() => {
											Mixpanel.track("Howto link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaQuestion />
										</i>
										How To
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] mb-4 w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/extensions'
										onClick={() => {
											Mixpanel.track("Resources link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<GrResources />
										</i>
										Tabin Extension
									</Link>
								</div>
								<div className='font-poppins font-normal cursor-pointer text-[18px] w-full hover:font-semibold'>
									<Link
										className='flex'
										to='/login'
										onClick={() => {
											Mixpanel.track("Login link NavBar Clicked");
										}}
									>
										<i className='mt-1'>
											<FaSignInAlt />
										</i>
										Login
									</Link>
								</div>
							</>
						)}
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
