import React from "react";
import styles from "../style";

const Stats = () => {
	return (
		<div
			className={`${styles.flexCenter} inline-container flex-wrap justify-evenly mb-0 xs:flex sm:mb-0 `}
		>
			<div className='rs:m-0 rs:p-0 m:m-1 sm:p-1'>
				<div className='justify-start items-center w-[auto] xs:m-20 sm:m-1'>
					<img
						src='up_arrow.svg'
						alt='up_arrow'
						className='h-[150px] w-[150px] ml-12 mb-8'
					/>
					<h4 className='font-poppins font-semibold xs:text-[40px] text-[30px] '>
						35%
					</h4>
					<p className='font-poppins font-normal xs:text-[20px] text-[15px]  text-gradient-dark uppercase ml-2'>
						Boost in Productivity
					</p>
				</div>
			</div>
			<div className='rs:m-0 rs:p-0 sm:m-10 sm:p-10 lg:px-14'>
				<div className='justify-start items-center w-[auto] xs:m-8 sm:m-1'>
					<img
						src='clock.svg'
						alt='clock'
						className='h-[150px] w-[150px] ml-2 mb-8'
					/>
					<h4 className='font-poppins font-semibold xs:text-[40px] text-[30px] '>
						5x
					</h4>
					<p className='font-poppins font-normal xs:text-[20px] text-[15px]  text-gradient-dark uppercase ml-2'>
						Time Saved
					</p>
				</div>
			</div>
			<div className='rs:m-0 rs:p-0 sm:m-10 sm:p-10 lg:px-14'>
				<div className='justify-start items-center w-[auto] xs:m-20 sm:m-1'>
					<img
						src='tab.svg'
						alt='tab'
						className='h-[150px] w-[150px] m-7 sm:ml-14 sm:mb-8 sm:m-0'
					/>
					<h4 className='font-poppins font-semibold xs:text-[40px] text-[30px]'>
						2500+
					</h4>
					<p className='font-poppins font-normal xs:text-[20px] text-[15px]  text-gradient-dark uppercase ml-2'>
						Tabins Created by Users
					</p>
				</div>
			</div>
		</div>
	);
};

export default Stats;
