import React, { useEffect } from "react";
import { Mixpanel } from "../app/mixpanel-instance";

const Cookies = () => {
	useEffect(() => {
		Mixpanel.track_pageview();
	}, []);
	return (
		<div>
			<iframe
				className='w-full'
				height='1000px'
				src='https://www.iubenda.com/privacy-policy/45640188/cookie-policy'
				title='Privacy Policy'
			/>
		</div>
	);
};

export default Cookies;
